import ProductAvailableStoresCount, {
    getProductAvailabilityEShopTwClass,
    productAvailabilityTwClass,
} from './Availability/ProductAvailableStoresCount';
import ProductFlags from './Flags/ProductFlags';
import ProductPrice from './Price/ProductPrice';
import SimpleColorVariants from './SimpleColorVariants/SimpleColorVariants';
import { ProductWishlistButton } from './Wishlist/ProductWishlistButtons';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import ProductAction from 'components/Blocks/Product/Action/ProductAction';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { twJoin } from 'tailwind-merge';
import { AvailabilityStatusEnum } from 'types/availability';
import { GtmListNameType } from 'types/gtm';
import { SliderProductItemType } from 'types/product';
import { onClickProductDetailGtmEventHandler } from 'utils/Gtm/EventHandlers';

type SliderProductItemProps = {
    product: SliderProductItemType;
    gtmListName: GtmListNameType;
    listIndex: number;
    selected: boolean;
    isContentVisible: boolean;
    shouldRedirectOnClick?: boolean;
    onProductItemClick?: (product: SliderProductItemType) => void;
    isConfigurator?: boolean;
    isProductInWishlist: boolean;
    toggleProductInWishlist: () => void;
};

export const SliderProductItem: FC<SliderProductItemProps> = ({
    gtmListName,
    listIndex,
    product,
    selected,
    isContentVisible,
    onProductItemClick,
    shouldRedirectOnClick = true,
    isConfigurator,
    isProductInWishlist,
    toggleProductInWishlist,
}) => {
    const testIdentifier = 'blocks-product-sliderproductitem-';
    const { url, type: domainType } = useDomainConfig();
    const t = useTypedTranslationFunction();

    const { isUserLoggedIn } = useCurrentUserData();
    const isActionPrice = product.price.priceWithoutVat < product.prices.basic.priceWithoutVat;
    const isElectronicGiftVoucher = product.isElectronicGiftVoucher;

    return (
        <div
            className={twJoin(
                'relative flex min-h-full w-[calc(100%-120px)] flex-shrink-0 flex-col justify-between px-2 text-primary md:w-[calc(100%/2)] lg:w-[calc(100%/3)] vl:w-[calc(100%/4)] xl:w-[calc(100%/5)]',
                'lg:after:absolute lg:after:left-2 lg:after:top-0 lg:after:right-2 lg:after:bottom-0 lg:after:-z-above lg:after:hidden lg:after:rounded-md lg:after:bg-white lg:after:content-[""]',
                'hover:after:lg:block',
                !selected && 'pointer-events-none opacity-[50] md:pointer-events-auto md:opacity-100',
            )}
            data-testid={testIdentifier + product.catalogNumber}
            onClick={() => onProductItemClick?.(product)}
        >
            {isContentVisible && (
                <div className="flex h-full flex-col justify-between">
                    <div className="mb-2 flex justify-end gap-2">
                        <ProductWishlistButton
                            isMainVariant={product.isMainVariant}
                            isProductInWishlist={isProductInWishlist}
                            toggleProductInWishlist={toggleProductInWishlist}
                        />
                    </div>
                    <ExtendedNextLink
                        className="relative flex h-full flex-col justify-between no-underline hover:no-underline not-last:pb-0 lg:py-4 lg:px-5 vl:px-6"
                        onClick={() => onClickProductDetailGtmEventHandler(product, gtmListName, listIndex, url)}
                        href={product.slug}
                        type="product"
                        shouldRedirectOnClick={shouldRedirectOnClick}
                    >
                        <>
                            <div>
                                <div
                                    className="relative mb-1 flex h-36 w-full items-center justify-center bg-transparent text-[0] lg:h-48"
                                    data-testid={testIdentifier + 'image'}
                                >
                                    <div className="relative flex h-40 w-full max-w-[265px] items-center justify-center lg:w-[176px]">
                                        <Image
                                            className="mix-blend-multiply"
                                            width={176}
                                            height={160}
                                            src={product.image}
                                            alt={product.fullName}
                                        />
                                    </div>
                                </div>
                                <SimpleColorVariants colorVariants={product.colorVariants} />
                                <div className="mb-2 flex min-h-[28px] flex-row flex-wrap items-start gap-1">
                                    <ProductFlags flags={product.flags} product={product} isProductDetail={false} />
                                </div>
                                <h3
                                    className={twJoin(
                                        'mb-3 block overflow-hidden text-body font-normal tracking-wider text-black line-clamp-4 [word-wrap:break-word]',
                                        product.fullName.length > 50 ? 'leading-5' : 'leading-6',
                                    )}
                                    data-testid={testIdentifier + 'name'}
                                >
                                    {product.fullName}
                                </h3>
                            </div>

                            {domainType !== 'web' && (
                                <div>
                                    <div className="mt-auto text-small">
                                        {product.prices.tescoma && isUserLoggedIn === false && (
                                            <ProductPrice {...product.prices.tescoma} isTescomaPrice />
                                        )}
                                        {isActionPrice && <ProductPrice {...product.prices.basic} isOldPrice />}
                                    </div>
                                    <div className="slider-product-item__bottom">
                                        <ProductPrice {...product.price} isActionPrice={isActionPrice} />
                                        <div className={productAvailabilityTwClass}>
                                            <span
                                                className={getProductAvailabilityEShopTwClass(
                                                    product.availabilityStatus === AvailabilityStatusEnum.inStock,
                                                )}
                                            >
                                                {product.availability.status === AvailabilityStatusEnum.inStock
                                                    ? t('Available in eshop')
                                                    : t('Unavailable in eshop')}
                                            </span>
                                            {!isElectronicGiftVoucher && (
                                                <ProductAvailableStoresCount
                                                    isMainVariant={product.isMainVariant}
                                                    availableStoresCount={product.availableStoresCount}
                                                    isInCentralStore={
                                                        product.availabilityStatus === AvailabilityStatusEnum.inStock
                                                    }
                                                    deliveryDaysFromCentralStore={product.deliveryDaysFromCentralStore}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    </ExtendedNextLink>
                    <ProductAction
                        isConfigurator={isConfigurator}
                        product={product}
                        gtmListName={gtmListName}
                        listIndex={listIndex}
                    />
                </div>
            )}
        </div>
    );
};
