import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { HeartFullIcon, HeartIcon } from 'public/svg/IconsSvg';
import { HTMLAttributes } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

type ProductCompareButtonProps = {
    isMainVariant: boolean;
    isWithText?: boolean;
    isProductInWishlist: boolean;
    toggleProductInWishlist: () => void;
};

const TEST_IDENTIFIER = 'wishlist-button';

export const ProductWishlistButton: FC<ProductCompareButtonProps & NativeProps> = ({
    className,
    isMainVariant,
    isWithText,
    isProductInWishlist,
    toggleProductInWishlist,
}) => {
    const t = useTypedTranslationFunction();

    if (isMainVariant) {
        return null;
    }

    return (
        <div className={twMerge('mb-2 flex items-center', className)}>
            <div
                className="flex cursor-pointer items-center"
                data-testid={TEST_IDENTIFIER}
                title={isProductInWishlist ? t('Remove product from wishlist') : t('Add product to wishlist')}
                onClick={toggleProductInWishlist}
            >
                {isProductInWishlist ? (
                    <HeartFullIcon className="text-grey mr-2 ml-0 text-red" />
                ) : (
                    <HeartIcon className="text-grey mr-2 ml-0" />
                )}

                {isWithText && (
                    <span className="ml-1">
                        {isProductInWishlist ? t('Remove from wishlist') : t('Add to wishlist')}
                    </span>
                )}
            </div>
        </div>
    );
};

ProductWishlistButton.displayName = 'ProductWishlistButton';
