var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c8458c46"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const dsn: string = publicRuntimeConfig.sentryDsn;
const environment: string = publicRuntimeConfig.sentryEnvironment;

Sentry.init({
    dsn: dsn,
    environment: environment,
    tracesSampleRate: 0.1,
});
